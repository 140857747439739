<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Add Service Region</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="serviceRegionName">Name <span class="red-dot ml-3"></span></label>
        <input id="serviceRegionName" name="serviceRegionName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('serviceRegionName') }"
          autocomplete="off" placeholder="Name" v-model="serviceRegion.name" v-validate="'required'" data-vv-as="Name" />
        <div class="invalid-feedback">The Name field is required.</div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: 'service-region',
  data: function () {
    return {
      isEdit: false,
      serviceRegion: {}
    }
  },
  computed: {
    ...mapGetters(["getServiceRegion"])
  },
  mounted: function() {
    this.serviceRegion = { ...this.getServiceRegion };
    this.isEdit = !!this.serviceRegion.id;
    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveServiceRegion({ data: this.serviceRegion, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.$bvModal.hide("bv-modal-service-region");
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-service-region");
    },
    ...mapActions(["saveServiceRegion"])
  }
}
</script>
